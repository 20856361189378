import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The pain is slowly going away. I read somewhere that was a bad sign. I can’t feel my legs anymore and my hands are as cold as ice. I’m lying in a pool of blood. I don’t even know if it’s mine or someone else's. We barely had time to process what was happening. It started off like a normal day, we got to school and I got off the bus with my friends. We walked to class cracking jokes and talking about nothing in particular. Our first class was math, and we were scrambling to finish our homework before the teacher came in. Halfway through class we heard two loud bangs ring throughout the school.`}</p>
    <p>{`I looked at my friends one by one and they had the same looks on their faces that I had on mine. A look of confusion and fright, everyone was staring at the door. I looked over at our teacher who was already pulling down the blinds. Another bang followed by a scream so loud it shook the walls of our classroom. The look of realization hit everyone’s faces, they were gunshots. I started to panic, I couldn’t move. It was like I was glued to my seat. Everyone stared at the teacher, expecting some kind of reassurance, instead, we heard another gunshot. Everyone scurried to the corners of the room. Everyone reached for their phones. We needed to make sure people knew what was going on. Maybe help could get here in time.`}</p>
    <p>{`The eerie silence was so loud it made our ears ache as we waited for any more clues as to where the shooter was. The school looked abandoned and dead. I wrapped my arms around my legs and rested my head on my knees. I was trying my best not to cry. Trying my best not to make a sound. There was a collective gasp as we heard someone run down the hall, screaming to open the door. We heard them stop at our door, panting and crying shakily. Then a sharp gasp and a high pitched scream. They shouted and kicked at our door. A gunshot. The screams stopped.`}</p>
    <p>{`The only thing between me and death was a wooden door. I felt a cold tear stream down the side of my face as I faced the possibility that this is how I was going to die. My mind started to race and my thoughts were clouding my brain. What if I died here? What if I never got to see the world? What if the last thing my mom heard from me was a grunt as I walked out of the door? No, I couldn’t think like that, I had to get out of here alive.`}</p>
    <p>{`The door got kicked down with a thud and the sound of the gun shots made my eardrums burst and I screamed as I saw the shooter aim the gun at my classmates. I knew my turn was coming, so I turned my back and crawled under the desk. I pulled my backpack toward me and had it protect my back from the shooter. I breathed out shakily as I covered my head with my arms and waited. Waited for my turn.`}</p>
    <p>{`I woke up and felt all the air escape from my lungs. I was shot. I was scared. I tried to scream, but all I could do was stay on the floor gasping for air. I tilted my head back and saw some of my friends lying motionless on the ground. I cried out, but it sounded more like a croak. I knew the shooter had left already, but I didn’t move. I couldn't, I was in shock. I felt a shiver down my spine and realized just how cold I was. The silence fell over me like a blanket as ice cold tears streamed down my face. I couldn’t feel anything anymore.`}</p>
    <p>{`I didn’t want to die here. I tried to find the source of the bleeding. I could feel myself slipping in and out of consciousness. I was hyperventilating and couldn’t think straight. The air seemed thin as I tried to sit up. A sharp pain hit me in the back and I decided that was probably where I got shot. I started to cry as a wave of panic washed over me. I pulled my jacket and pushed it under my back to stop the bleeding. I knew that was the first step. I didn’t know what to do next, so I just layed back down and tried to steady my breathing. As I lay there on the ground, all I could think about was what I haven’t been able to do in my life. I’m only sixteen, I had to keep living. I couldn’t let them win. I thought of my family as I tried to stay awake but it was getting harder by the minute. I turned my head slightly to look up at the window. The sun shined on my face, but there were no sounds, no sirens. No one was coming, I was all alone. I was dying and I knew it. My eyes started to close, but I couldn’t do anything about it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      